import React from 'react';
import { VjcButton } from './VjcButton';
import styles from '../components/styles/box.module.css';

export const VjcBox = ({ boxProps }) => {
  const {
    boxId,
    boxClassName,
    boxTitle,
    boxContent,
    boxContentClassName,
    boxContentContainerClassName,
    buttonTitle,
    link,
    buttonClassName,
  } = boxProps;

  return (
    <>
      <section id={boxId} className={`${styles.box} ${boxClassName}`}>
        {!!boxTitle && (
          <h1 className={styles.boxTitle} text-value={boxTitle}>
            {boxTitle}
          </h1>
        )}
        <div
          className={`${styles.boxContentContainer} ${boxContentContainerClassName}`}
        >
          <section className={`${styles.boxContent} ${boxContentClassName}`}>
            {boxContent}
          </section>
          {!!buttonTitle && (
            <VjcButton
              buttonTitle={buttonTitle}
              link={link}
              buttonClassName={buttonClassName}
            />
          )}
        </div>
      </section>
    </>
  );
};
