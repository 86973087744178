import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from '../components/styles/index.module.css';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SwiperCore, { Scrollbar, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const News = () => {
  SwiperCore.use([Scrollbar, Mousewheel]);

  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpIndexNews(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: date, order: DESC }
        limit: 8
      ) {
        edges {
          node {
            date(formatString: "YYYY.MM.DD")
            content {
              json
            }
          }
        }
      }
    }
  `);

  const swiperProps = {
    tag: 'ol',
    direction: 'vertical',
    scrollbar: { draggable: true, hide: false, snapOnRelease: true },
    mousewheel: { sensitivity: 0, releaseOnEdges: false },
    slidesPerGroup: 2,
    slidesOffsetAfter: 30,
    slidesPerView: 3,
    breakpoints: {
      321: {
        slidesPerView: 4,
      },
      550: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <Swiper className={styles.swiper} {...swiperProps}>
      {data.allContentfulVjcHpIndexNews.edges.map((edge, i) => {
        return (
          <SwiperSlide key={i} tag='li'>
            <span className={styles.newsDate}>{edge.node.date}</span>
            <span className={styles.newsContent}>
              {documentToReactComponents(edge.node.content.json)}
            </span>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
