import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { Header } from './Header';
import VerticalNavigation from './VerticalNavigation';
import { Footer } from './Footer';
import './styles/destyle.css';
import './styles/layout.css';
import './styles/material-ui.css';

const Layout = ({ children, isTopPage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  const menuLinks = data.site.siteMetadata.menuLinks.slice(0, 6);

  return (
    <>
      <Header menuLinks={menuLinks} isTopPage={isTopPage} />
      <VerticalNavigation menuLinks={menuLinks} isTopPage={isTopPage} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
