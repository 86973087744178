import React from 'react';
import { Link } from 'gatsby';
import styles from './styles/vertical-navigation.module.css';

export const VerticalNavigation = ({ menuLinks, isTopPage }) => {
  const Menu = () => {
    return (
      <ul className={styles.verticalNavMenuList}>
        {menuLinks
          .filter((menuLink) => menuLink.name !== 'HOME')
          .map((link, i) => (
            <li className={styles.verticalNavMenuItem} key={`link-${i}`}>
              <Link to={link.link} className={styles.verticalNavMenuLink}>
                {link.name}
              </Link>
            </li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <nav
        className={`${styles.verticalNav} ${!isTopPage ? styles.hidden : ''}`}
      >
        <Menu />
      </nav>
    </>
  );
};

export default VerticalNavigation;
