import React, { useState } from 'react';
import { Link } from 'gatsby';
import { window } from 'ssr-window';
import styles from './styles/header.module.css';
import logoSVG from '../images/vjcLogo.png';

export const Header = ({ menuLinks, isTopPage }) => {
  const ANIMATION_TRIGER_POSITION = 600;
  const HIDDEN_HEADER_POSITION = 200;

  const [currentPosition, setCurrentPosition] = useState(
    parseInt(window.pageYOffset)
  );

  const scrollHeaderEvent = () => {
    setCurrentPosition(parseInt(window.pageYOffset));
  };

  const isAfterAnimationPosition = () =>
    currentPosition > ANIMATION_TRIGER_POSITION;

  const isAfterHiddenHeaderPosition = () =>
    currentPosition > HIDDEN_HEADER_POSITION;

  const [toggleMenuActiveState, setToggleMenuActiveState] = useState(false);

  const overflowControl = () => {
    if (!toggleMenuActiveState) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  };

  const toggleMenuScrollHandler = (e) => {
    if (!toggleMenuActiveState) {
      e.preventDefault();
    } else {
      e.stopPropagation();
    }
  };

  const iosScrollControl = (e) => {
    if (!toggleMenuActiveState) {
      document.addEventListener('touchmove', toggleMenuScrollHandler(e), {
        passive: false,
      });
    } else {
      document.removeEventListener('touchmove', toggleMenuScrollHandler(e), {
        passive: false,
      });
    }
  };

  const switchToggleMenuActiveState = (e) => {
    setToggleMenuActiveState(!toggleMenuActiveState);
    overflowControl(e);
    iosScrollControl(e);
  };

  window.addEventListener('scroll', scrollHeaderEvent, {
    passive: true,
  });

  const Menu = () => {
    return (
      <ul className={styles.globalNavMenuList}>
        {menuLinks.map((link, i) => (
          <li className={styles.globalNavMenuItem} key={`menu-${i}`}>
            <Link to={link.link} className={styles.globalNavMenuLink}>
              <p>{link.name}</p>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <header className={styles.header}>
        <div
          className={`${styles.globalNav} ${
            !isTopPage || (!!isTopPage && isAfterAnimationPosition())
              ? styles.headerShow
              : styles.headerHidden
          } ${
            !!isTopPage && !isAfterHiddenHeaderPosition() ? styles.hidden : ''
          }`}
        >
          <div className={styles.logoWrap}>
            <Link to='/'>
              ​<img src={logoSVG} alt='' className={styles.logo} />
            </Link>
          </div>
          <nav className={`${styles.globalNavMenu} ${styles.pc}`}>
            <Menu />
          </nav>
        </div>
        <div id={styles.toggleIcon}>
          <section>
            <div
              role='presentation'
              id={styles.animationTriggerBtn}
              className={`${styles.animationTriggerBtn} ${
                toggleMenuActiveState ? styles.toggleMenuActive : ' '
              }`}
              onClick={switchToggleMenuActiveState}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </section>
        </div>
        <div
          role='presentation'
          id={styles.toggleMenu}
          className={toggleMenuActiveState ? styles.toggleMenuShow : ''}
          onClick={switchToggleMenuActiveState}
        >
          <nav className={styles.globalNavMenu}>
            <Menu />
          </nav>
        </div>
      </header>
    </>
  );
};
