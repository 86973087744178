import React, { useState } from 'react';
import SEO from '../components/Seo';
import Layout from '../components/Layout';
import styles from '../components/styles/index.module.css';
import { VjcBox } from '../components/VjcBox';
import { News } from '../components/News';
import vjcMainLogo from '../images/vjcMainLogo.png';
import { window } from 'ssr-window';

export default () => {
  const [currentPosition, setCurrentPosition] = useState(
    parseInt(window.pageYOffset)
  );
  const scrollEvent = () => {
    setCurrentPosition(parseInt(window.pageYOffset));
  };
  window.addEventListener('scroll', scrollEvent, {
    passive: true,
  });
  const companyContent =
    'あなたにとってストレスとは何でしょうか。バークリー・ジャパン株式会社は、人々のストレスを軽減し、日々の生活や働き方に変化をもたらすような、新たな価値のあるサービスを創成・提供いたします。';
  const serviceContent =
    '当社はお客様の社内システムや顧客向けサービスをWeb技術を用いて、より良いサービスへのご提供を可能にするWebソリューション企業です。ECサイトから業務自動化やサービスのクラウド移行、UI/UXの些細な改修など、まずはお気軽にご相談ください。';

  const recruitContent = (
    <p>
      当社は、「ストレスフリーの社会に」を理念に掲げ、2019年に設立いたしました。
      <br />
      この理念のもと、顧客企業ひいてはその先にある消費者のストレスを軽減するサービスをともに作りあげていくことのできるメンバーを募集しています。
    </p>
  );

  const newsBoxProps = {
    boxClassName: styles.newsBox,
    boxTitle: 'NEWS',
    boxContent: News(),
    boxContentClassName: styles.newsBoxContent,
    buttonTitle: '',
    link: '',
    buttonClassName: styles.button,
  };

  const companyBoxProps = {
    boxClassName: styles.companyBox,
    boxTitle: 'COMPANY',
    boxContent: companyContent,
    boxContentClassName: styles.boxContent,
    buttonTitle: 'MORE ▶︎',
    link: '/company/',
    buttonClassName: styles.button,
  };

  const serviceBoxProps = {
    boxClassName: styles.serviceBox,
    boxTitle: 'SERVICE',
    boxContent: serviceContent,
    boxContentClassName: styles.boxContent,
    buttonTitle: 'MORE ▶︎',
    link: '/service/',
    buttonClassName: styles.button,
  };

  const recruitBoxProps = {
    boxClassName: styles.recruitBox,
    boxTitle: 'RECRUIT',
    boxContent: recruitContent,
    boxContentClassName: styles.boxContent,
    buttonTitle: 'MORE ▶︎',
    link: '/recruit/',
    buttonClassName: styles.button,
  };

  return (
    <Layout isTopPage={true}>
      <SEO title='トップページ'></SEO>
      <div>
        <div className={styles.varclyLogoContainer}>
          <div className={styles.varclyLogoContent}>
            <img className={styles.varclyLogo} src={vjcMainLogo} alt='' />
            <h2 className={styles.varclyMessage}>ストレスフリーの社会に</h2>
          </div>
        </div>
      </div>
      <div className={styles.scrollBox}>
        <p className={styles.scrollMessage}>SCROLL</p>
      </div>
      <div
        className={
          currentPosition > 5 ? styles.mainMessage : styles.mainMessageAnimation
        }
      >
        <p>
          様々なIT技術やWebサービスを通じて
          <br />
          利便性を追求し
          <br />
          社会で働く人々のストレスを軽減する
        </p>
      </div>
      <div className={styles.boxes}>
        <div className={styles.newsAndCompanyContainer}>
          <VjcBox boxProps={newsBoxProps} />
          <VjcBox boxProps={companyBoxProps} />
        </div>
        <div className={styles.serviceAndRecruitContainer}>
          <VjcBox boxProps={serviceBoxProps} />
          <VjcBox boxProps={recruitBoxProps} />
        </div>
      </div>
    </Layout>
  );
};
