import React from 'react';
import { Link } from 'gatsby';
import styles from '../components/styles/button.module.css';

export const VjcButton = ({ buttonTitle, link, buttonClassName }) => {
  return (
    <Link
      to={link}
      className={`${styles.button} ${buttonClassName}`}
      text-value={buttonTitle}
    >
      {buttonTitle}
    </Link>
  );
};
