import React from 'react';
import { Link } from 'gatsby';
import styles from './styles/footer.module.css';

export const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.copyright}>
      <p>Copyright © {new Date().getFullYear()} VarclyJapan co.,Ltd.</p>
    </div>
    <ul className={styles.footerMenu}>
      <li className={styles.footerMenuItem}>
        <Link to='/privacy' className={styles.footerMenuLink}>
          プライバシーポリシー
        </Link>
      </li>
    </ul>
  </footer>
);
